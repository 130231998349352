import { Container } from "./styles";
import githubIcon from "../../assets/github.svg"
// import DownloadApp from '../../assets/download.png'
import externalLink from "../../assets/external-link.svg"
import ScrollAnimation from "react-animate-on-scroll";
import img1 from "../../assets/pr1.png"
import img2 from "../../assets/pr3.png"
import img3 from  "../../assets/pr2.png"
import img4 from  "../../assets/ttt.png"
import img5 from  "../../assets/ny.png"
import img6 from  "../../assets/ir.png"
import img7 from  "../../assets/sg.png"
import img8 from  "../../assets/prSekolah.jpg"
import img9 from  "../../assets/prKereta.jpg"
import img10 from  "../../assets/sd.webp"
import arrow from "../../assets/arrow.gif"

export function Project() {
  
  return (
    <Container id="project">
     
      <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
      <h2>Projects</h2>
      </ScrollAnimation>
      <div className="projects">

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"> <title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
              <div className="project-links">
                <a href="https://github.com/septianhdyt" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" /></a>
                <a href="https://tokojam.netlify.app" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a> </div>
            </header>
            <div className="body">
              <h3>Prototype Website "Watchify"</h3>
              <p> Developed a simple smartwatch shop website with ReactJs and Tailwind CSS </p>
              <img src={img1} alt="Website Watchify Septian" />
            </div>
            <footer> <ul className="tech-list"> <li>React JS</li> <li>Tailwind CSS</li> <li>UI/UX</li> </ul> </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
              <div className="project-links">
                <a href="https://github.com/septianhdyt" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" /> </a>
                <a href="https://septianhdyt.github.io/clone-spotify" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" /></a>
              </div>
            </header>
            <div className="body">
              <h3>Cloning UI App: Spotify</h3>
              <p>
                Developed a clone website ui ,Spotify with working music player
              </p> 
              <img src={img2} alt="Website Spotify Clone Septian" />
             
            </div>
            <footer>
              <ul className="tech-list">
                <li>React JS</li>
                <li>Tailwind CSS</li>
                <li>HTML</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://github.com/septianhdyt" target="\_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="https://nusantaratravel.netlify.app" target="\_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Noesantara Travel Web</h3>
              <p>
                Developed a travel website especially in Indonesia, with blog
              </p><img src={img3} alt="Website Noesantara Travel Septian" />
             
            </div>
            <footer>
              <ul className="tech-list">
                <li>React JS</li>
                <li>Tailwind CSS</li>
                <li>HTML</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://github.com/septianhdyt" target="\_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="https://septianbelajar.000webhostapp.com/wordpress/" target="\_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>SumedangGO | Blog App</h3>
              <p>
                Building a blog app website, contains about the Latest Sumedang Culture, Education, and many informations 
              </p><img src={img7} alt="Website SumedangGO Septian" />
            </div>
            <footer>
              <ul className="tech-list">
                <li>WordPress</li>
                <li>SEO</li>
                <li>UI/UX</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://github.com/septianhdyt" target="\_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="https://septianhdyt.github.io/tictactoe" target="\_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Tic Tac Toe ?</h3>
              <p>
                Make a simple program about tic-tac-toe game!
              </p><img src={img4} alt="Website Tic Tac Toe Septian" />
            </div>
            <footer>
              <ul className="tech-list">
                <li>React JS</li>
                <li>CSS</li>
                <li>HTML</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://github.com/septianhdyt" target="\_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="https://septianhdyt.github.io/webresidence" target="\_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Iann Residence | Web Perumahan</h3>
              <p>
              This website was created to display information about residences complete with contacts and blogs.
              </p><img src={img6} alt="Website IANN Residence Septian" />
            </div>
            <footer>
              <ul className="tech-list">
                <li>HTML</li>
                <li>CSS</li>
                <li>Javascript</li>
                <li>Bootstrap</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://github.com/septianhdyt" target="\_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="https://septianhdyt.github.io/webnontonyuk" target="\_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>NontonYuk</h3>
              <p>
              Creating a movie search website using API data and incorporating it into the Yuk Nonton database.
              </p><img src={img5} alt="Website Nonton Yuk Septian" />
            </div>
            <footer>
              <ul className="tech-list">
                <li>HTML</li>
                <li>CSS</li>
                <li>Javascript</li>
                <li>API</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/septianhdyt" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="septianhdyt.github.io/" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Niket Kereta | Desktop App</h3>
              <p>Developed a Desktop app with M.VS for manage train ticket data, see booking data,etc</p><img src={img9} alt="Desktop App Niket Kereta Septian" />
            </div>
            <footer>
              <ul className="tech-list">
                <li>C#</li>
                <li>API</li>
                <li>Laravel</li>
                <li>MySQL</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Code</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://github.com/septianhdyt" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>E-Student | Desktop app</h3>
              <p>Developed a Desktop app which for recap student's grade</p>
              <img src={img8} alt="Desktop App E-Student Septian" />
            </div>
            <footer>
              <ul className="tech-list">
                <li>C#</li>
                <li>SSMS</li>
                <li>CrystalReport</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#FD3A4A " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com.septianhdyt/" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Smart Dustbin</h3>
              <p>
                Make a smart dustbin with ultrasonic sensor Arduino Uno for my School Project. <span style={{fontStyle : "italic"}}>(The Image below is example)</span> 
              </p>
              <img src={img10} alt="Smart Dustbin by Septian" />
            </div>
            <footer>
              <ul className="tech-list">
                <li>IOT</li>
                <li>C</li>
                <li>Arduino</li>
                
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div> 
      <p style={{fontSize: "1pt",  textAlign : "center"}}> <img src={arrow} alt="arrow" style={{   width: "6.4rem"}} /></p>
    </Container>
    
  );
}