import React from 'react';
import { Container, Timeline, TimelineItem, DotImage, Title, Subtitle, Info, Text } from './styles';
import circle from "../../assets/circle.png"; // Import the image
import ScrollAnimation from 'react-animate-on-scroll';

export function Education() {
  return (
    
    <Container> 
      <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
      <Timeline>
        <TimelineItem align="right">
          <DotImage src={circle} alt="dot" />
          <div>
            <Title>Universitas Indraprasta PGRI</Title>
            <Subtitle>Informatics Engineering</Subtitle>
            <Info>Grade: <span style={{ fontStyle: "italic" }}>In Progress</span></Info>
            <Text>September 2024 - Present</Text>
          </div>
        </TimelineItem>
        <TimelineItem align="left">
          <DotImage src={circle} alt="dot" />
          <div>
            <Title>SMK Negeri 1 Sumedang</Title>
            <Subtitle>Software Engineering</Subtitle>
            <Text>July 2021 - May 2024</Text>
            <Text>Internship at Sumedang Prosecutor's Office</Text>
          </div>
        </TimelineItem>
        <TimelineItem align="right">
          <DotImage src={circle} alt="dot" />
          <div>
            <Title>(Freelance) Junior Web Developer</Title>
            <Subtitle>Remote</Subtitle>
            <Text>August 2024 - Present</Text>
            <Text>Programming Languages: HTML, JavaScript, PHP, C#, Java</Text>
            <Text>Frameworks:  Laravel, React.js, TailwindCSS, CodeIgniter, Bootstrap </Text>
          </div>
        </TimelineItem>
      </Timeline>
      </ScrollAnimation>
    </Container>
  );
}

export default Education;
