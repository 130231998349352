// src/components/Blog/AllBlogs.tsx
import React, { useState } from 'react';
import { blogsData } from './blogData';
import { AllBlogsContainer, BlogList, Sidebar } from './styles';
import ScrollAnimation from "react-animate-on-scroll";
import ian from "../../assets/iann.png"
import li from "../../assets/linkedin.svg"
import git from "../../assets/github.svg"
import ig from "../../assets/instagram.svg"

export function AllBlogs() {
  const [expandedBlogId, setExpandedBlogId] = useState<number | null>(null);

  const toggleExpand = (id: number) => {
    setExpandedBlogId(expandedBlogId === id ? null : id);
  };

  return (
    <AllBlogsContainer>
      <BlogList>
        <h1 style={{ color: 'white' }}>Blog saya</h1>
        {blogsData.map((blog) => {
          const fullContent = Array.isArray(blog.content) ? blog.content.join('\n') : blog.content || '';
          const fullContent2 = Array.isArray(blog.content2) ? blog.content2.join('\n') : blog.content2 || '';
          const isExpanded = expandedBlogId === blog.id;

          const contentToShow = isExpanded 
            ? fullContent + '\n' + fullContent2 
            : fullContent.substring(0, 100) + '...' + (fullContent2 ? '\n' + fullContent2.substring(0, 100) + '...' : '');

          return (
            <div key={blog.id} className="blog-post">
              <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 2000}>
                <h2>{blog.title}</h2>
                <p style={{ fontSize: '10pt' }}><strong>{blog.date}</strong> by {blog.author}</p>
                <br />
                <img src={blog.image} alt={blog.title} style={{ width: '100%', height: 'auto' }} />
                <h5 style={{marginTop:"3px",color:"gray",marginBottom:"20px"}}>Image by : {blog.credit}</h5>
                <p style={{ whiteSpace: 'pre-line' }}>{contentToShow}</p>
                {/* Memusatkan tombol Read More */}
                <button 
                  onClick={() => toggleExpand(blog.id)} 
                  style={{ 
                    cursor: 'pointer', 
                    border: 'none', // Menghilangkan border
                    background: 'none', // Menghilangkan latar belakang
                    color: '#FD3A4A', // Warna teks tombol
                    textAlign: 'center', // Memusatkan teks
                    display: 'block', // Mengatur tombol sebagai block agar dapat dipusatkan
                    margin: '0 auto', // Mengatur margin untuk memusatkan
                    padding: '10px 0', // Menambahkan padding untuk lebih menarik
                  }}
                >
                  {isExpanded ? 'Show Less' : 'Read More'}
                </button>
              </ScrollAnimation>
            </div>
          );
        })}
      </BlogList>
      
      <Sidebar>
        <img src={ian} alt="Septian Saleh Hidayat" style={{ borderRadius: '50%', width: '100px', height: '100px', marginBottom: '15px' }} />
        <h2>About Me</h2>
        <p style={{color: "var(--black)"}}>Hi! I'm a Junior Front-End Developer and Graphic Designer. I love creating beautiful and functional websites.</p>
<br /><hr /><br />
        <h2>Recent Posts</h2>
        <ul>
          {blogsData.slice(0, 5).map((blog) => (
            <li key={blog.id} style={{ marginBottom: '10px' }}>
              <a href={`/blog/${blog.id}`} style={{ color: '#FD3A4A', textDecoration: 'none' }}>{blog.title}</a>
            </li>
          ))}
        </ul>
        <br /><hr /><br />
        <h2>Categories</h2>
        <div className="sidebar-item">Web Development</div>
        <div className="sidebar-item">Graphic Design</div>
        <div className="sidebar-item">UI/UX Design</div>
        <br /><hr /><br />
        <h2>Follow Me</h2>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
          <a href="https://www.linkedin.com/in/septianhdyt" target="_blank" rel="noopener noreferrer">
            <img src={li} alt="LinkedIn" style={{ width: '30px', height: '30px' }} />
          </a>
          <a href="https://github.com/septianhdyt" target="_blank" rel="noopener noreferrer">
            <img src={git} alt="GitHub" style={{ width: '30px', height: '30px' }} />
          </a>
          <a href="https://instagram.com/iannsl9" target="_blank" rel="noopener noreferrer">
            <img src={ig} alt="Instagram" style={{ width: '30px', height: '30px' }} />
          </a>
        </div>
      </Sidebar>
    </AllBlogsContainer>
  );
}
