// src/components/Blog/styles.ts
import styled from 'styled-components';

export const BlogContainer = styled.div`
  width: 100vw; /* Memastikan container memenuhi seluruh lebar viewport */
  margin: 0; /* Pastikan tidak ada margin */
  padding: 0; /* Pastikan tidak ada padding */
  overflow-x: hidden; /* Pastikan tidak ada scroll horizontal */
  


`;

export const BlogCard = styled.div`
  display: flex;
  flex-direction: column; /* Adjust to column layout */
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: #fff;
  margin: 0;
  padding: 20px 0;
  cursor : pointer;
  height: auto; /* Auto height to fit content */

  img {
    width: 100%; /* Full width for images */
    max-height: 400px;
    object-fit: cover;
    margin-top: 20px; /* Add some spacing */
  }

  .text-content {
    width: 50%; /* Restrict width of text content */
    text-align: center;
    background-color: #fff;
    padding: 20px;
  }

  h3 {
    font-size: 2rem; /* Font size for title */
    color: #333;
    margin-bottom: 10px;
  }
     h5 {
    font-size: 1.1rem; /* Font size for title */
    color: #333;
    
    margin-bottom: 10px;
  }

  .blog-info {
    font-size: 1rem; /* Date and author */
    color: #777;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    color: #555;
  }

  button {
    background: #fd3a4a;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: #e02e3a;
    }
  }
`;


export const AllBlogsContainer = styled.div`
margin-top:10rem;
  display: flex;
  flex-direction: row;
  padding: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BlogList = styled.div`
  flex: 3;
  margin-right: 20px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #FD3A4A;
  }

  .blog-post {
    margin: 20px 0;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    }

    img {
      max-width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    h2 {
      margin: 10px 0;
      color: #FD3A4A;
    }

    p {
      color: #555;
      font-size: 16pt;
      text-align: justify;
    }
  }
`;

export const Sidebar = styled.div`
flex: 1;
background: #f8f9fa;
padding: 20px;
border-radius: 10px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

/* Batas tinggi maksimum sidebar agar tidak memanjang ke bawah */
max-height: 500px; /* Atur sesuai kebutuhan */
overflow-y: auto; /* Jika konten terlalu panjang, sidebar akan bisa di-scroll sendiri */

/* Menambahkan sticky position untuk mempertahankan sidebar di tempat */
position: sticky;
top: 20px;

h2 {
  margin-bottom: 15px;
  color: #333;
}

.sidebar-item {
  margin-bottom: 10px;
  color: var(--black);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 768px) {
  position: static; /* Ubah menjadi static agar sidebar berada di atas dalam tampilan mobile */
  margin-bottom: 20px; /* Tambahkan margin bawah agar ada jarak dari konten di bawah */
  max-height: none; /* Hapus batas tinggi maksimum untuk tampilan mobile */
}
`;

