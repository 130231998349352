import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Blog } from './components/Blog/Blog';
import { AllBlogs } from './components/Blog/DetailBlog';
import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { Contact } from './components/Contact/Contact';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { Main } from './components/Main/Main';
import { GlobalStyle } from './styles/global';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <ScrollToTop /> {/* Scroll to top on route change */}
        <Header />
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <Main />
                <br /><br /><hr /><br /><ScrollAnimation animateIn="fadeInUp">
                <h1 style={{textAlign:"center"}}>Blogs</h1></ScrollAnimation><br />
                <Blog />
                <br /> <hr />
                <Contact />
              </>
            } 
          />
          <Route path="/blogs" element={<AllBlogs />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
