
import { Container } from "./styles"
import ScrollAnimation from "react-animate-on-scroll"
import Illustration from "../../assets/hd1.png"
import { NavHashLink } from "react-router-hash-link"
import linkedin from '../../assets/linkedin.svg'
import githubIcon from '../../assets/github.svg'
import whatsapp from '../../assets/whatsapp.svg'
import Hello from '../../assets/Hello.gif'
import instagram from '../../assets/instagram.svg'

export function Hero() {
  return (
    <Container id="home">
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp">
        
          <p>Hello <img src={Hello} alt="Hello" width="15px"/> , I'm</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
          <h1>Septian Saleh</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3>Junior Front-End Developer</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume">And Graphic Designer</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>
      
            <NavHashLink smooth to="#contact" className="button">Contact</NavHashLink>
        
        </ScrollAnimation>
       
      
    <div className="social-media">
    <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
    <div className="link">
        <a
        href="https://www.linkedin.com/in/septianhdyt"
        target="_blank"
        rel="noreferrer"
        >
        <img src={linkedin} alt="Linkedin" />
        </a>
    </div>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeInUp" delay={1.2 * 1000}>
    <div className="link">
        <a
        href="https://github.com/Septianhdyt/"
        target="_blank"
        rel="noreferrer"
        >
        <img src={githubIcon} alt="GitHub" />
        </a>
    </div>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeInUp" delay={1.4 * 1000}>
    <div className="link">
        <a
        href="https://api.whatsapp.com/send/?phone=%2B6283149619435&text=Hello+Septian"
        target="_blank"
        rel="noreferrer"
        >
        <img src={whatsapp} alt="Whatsapp" />
        </a>
    </div>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeInUp" delay={1.6 * 1000}>
    <div className="link">
        <a
        href="https://instagram.com/iannsl9"
        target="_blank"
        rel="noreferrer"
        >
        <img src={instagram} alt="instagram" />
        </a>
    </div>
    </ScrollAnimation>
   
    </div>
       
      </div>
      <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
          <img src={Illustration} alt="vector1" />
        </ScrollAnimation>
      </div>
    
    </Container>
  )
}