// styles.ts

import styled from "styled-components";
import { motion } from "framer-motion";

// Container Galeri
export const Container = styled.section`
  padding: 4rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0);
  margin-bottom: 10rem;
`;

// Grid Galeri
export const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tetap 3 kolom di semua layar */
  gap: 1rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr); /* Tetap 3 kolom di mobile */
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(3, 1fr); /* Tetap 3 kolom di ukuran layar kecil */
  }
`;

// Wrapper untuk Setiap Gambar
export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
  aspect-ratio: 1 / 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }

  .description-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;

    h3 {
      font-size: 1.2rem;
      color: #333;
      margin: 0;
    }

    p {
      font-size: 1rem;
      color: #555;
      margin-top: 0.5rem;
    }
  }

  &:hover .description-overlay {
    transform: translateY(0);
    opacity: 1;
  }

  /* Media query untuk ukuran font yang lebih kecil di layar mobile */
  @media (max-width: 768px) {
    .description-overlay {
      h3 {
        font-size: 1rem; /* Ukuran judul lebih kecil */
      }
      p {
        font-size: 0.5rem; /* Ukuran deskripsi lebih kecil */
        text-align: center;
      }
    }
  }

  @media (max-width: 480px) {
    .description-overlay {
      h3 {
        font-size: 0.9rem; /* Ukuran judul lebih kecil di layar lebih kecil */
      }
      p {
        font-size: 0.7rem; /* Ukuran deskripsi lebih kecil */
      }
    }
  }
`;

// Fullscreen Overlay dengan Animasi
export const FullscreenOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  z-index: 1000; /* Memastikan overlay berada di atas elemen lain */
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    max-width: 80%; /* Ubah sesuai kebutuhan */
    max-height: 80%; /* Ubah sesuai kebutuhan */
    object-fit: contain;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  @media (max-width: 1024px) {
    img {
      max-width: 85%;
      max-height: 85%;
    }
  }

  @media (max-width: 768px) {
    img {
      max-width: 90%;
      max-height: 90%;
    }
  }

  @media (max-width: 480px) {
    img {
      max-width: 95%;
      max-height: 95%;
    }
  }
`;

// Tombol Navigasi
export const NavButton = styled.button<{ left?: boolean }>`
  position: absolute;
  top: 50%;
  ${(props) => (props.left ? "left: 20px;" : "right: 20px;")}
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  z-index: 1001; /* Memastikan tombol berada di atas gambar */

  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }
`;

// Indikator Posisi Gambar
export const PositionIndicator = styled.div`
  position: absolute;
  bottom: 20px;
  color: #fff;
  font-size: 1rem;
  z-index: 1001; /* Memastikan indikator berada di atas gambar */
`;
